<template>
	<div class="breadCrumbs" :class="{ last: !last }">
		<template v-for="(item, index) in items">
			<router-link
				v-if="item.url"
				:to="item.url"
				:key="index"
				:style="{ paddingLeft: `${20 * index}px` }"
				:class="{ hasIco: index > 0 }"
				>{{ item.text }}</router-link
			>
			<div
				v-else
				:key="index"
				:style="{ paddingLeft: `${20 * index}px` }"
				:class="{ hasIco: index > 0 }"
			>
				{{ item.text }}
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "BreadCrubms",
	props: ["items", "last"],
};
</script>

<style lang="scss">
.breadCrumbs {
	a,
	div {
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 120%;
		color: #7f8192;
		padding: 2px 0;
		display: block;
		text-decoration: none;
		position: relative;
		&:hover {
			color: $cblacktxt;
		}
		&.hasIco:before {
			content: "\e915";
			position: absolute;
			font-size: 20px;
			color: $cred1;
			transform: translate(-100%, -50%);
			top: 50%;
			font-family: "Biopharma" !important;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
	&.last {
		*:last-child {
			pointer-events: none;
		}
	}
}
</style>