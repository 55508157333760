<template>
    <div class="prodsWrp">
        <slot></slot>
        <div
            class="dummy"
            v-for="(n, index) in blanks"
            :key="'blank' + index"
        ></div>
    </div>
</template>

<script>
export default {
    name: "ProductsGrid",
    props: ["count"],
    data() {
        return {
            inRow: 3
        };
    },
    methods: {
        onResize() {
            this.inRow = 3;
            const w = window.innerWidth;
            if (w > 600) return (this.inRow = 3);
            else return (this.inRow = 2);
        }
    },
    computed: {
        blankCount() {
            return this.count % this.inRow != 0
                ? this.inRow - (this.count % this.inRow)
                : 0;
        },
        blanks() {
            let arr = [];
            for (let index = 0; index < this.blankCount; index++) {
                arr.push("s");
            }
            return arr;
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    }
};
</script>

<style lang="scss">
.prodsWrp {
    background-color: $cgray6;
    display: grid;
    flex-wrap: wrap;
    grid-gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: $ts) {
        grid-template-columns: repeat(2, 1fr);
    }
    .dummy {
        background-color: #fff;
    }
}
</style>
