<template>
    <router-link
        :to="{
            name: 'product',
            params: {
                locale: $i18n.locale == 'uk' ? null : $i18n.locale,
                category: d.model.categorySlug,
                slug: d.model.slug
            }
        }"
        class="prodItem"
        :class="side"
    >
        <div class="imgWrp">
            <img
                v-if="d.model.image"
                :src="path(d.model.image)"
                :alt="path(d.model.image)"
            />
        </div>
        <div class="prodInfo">
            <div class="prodTitle">{{ d.translate.name }}</div>
            <div class="description">{{ d.translate.release_form }}</div>
            <ul>
                <li
                    v-for="(item, index) in d.translate.catalogIndication"
                    :key="index"
                >
                    {{ item }}
                </li>
            </ul>
            <router-link :to="d.model.categoryUrl" class="tag">{{
                d.model.categoryName
            }}</router-link>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "ProductGridCard",
    props: ["sidemode"],
    computed: {
        side() {
            return this.sidemode ? "min" : null;
        }
    }
};
</script>

<style lang="scss">
.prodItem {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    text-decoration: none;
    .imgWrp {
        margin: 40px 40px 25px;
        height: 237px;
        @media (max-width: $ts) {
            height: 180px;
            margin: 15px 10px 5px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .prodInfo {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .prodTitle {
            @include h5;
            padding: 10px 15px;
            @media (max-width: $tl) {
                @include t3;
            }
            @media (max-width: $ts) {
                @include t4;
                padding: 10px;
            }
        }
        .description {
            @include t4;
            padding: 0 15px;
            color: $cgray3;
            @media (max-width: $ts) {
                @include t5;
                color: $cgray3;
                padding: 0 10px;
            }
        }
        ul {
            flex-grow: 1;
            list-style: none;
            padding: 0 15px;
            margin: 10px 0;
            @media (max-width: $ts) {
                padding: 0 10px;
            }
            li {
                @include t4l;
                position: relative;
                padding-left: 30px;
                @media (max-width: $ts) {
                    @include t5;
                }
                & + li {
                    margin-top: 15px;
                }
                &:before {
                    content: "\e909";
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 20px;
                    color: $cgray3;

                    font-family: "Biopharma" !important;
                    speak: never;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
        .tag {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            text-decoration-line: underline;
            color: #7f8192;
            margin: 5px 15px 20px;
            @media (max-width: $ts) {
                font-size: 12px;
                margin: 5px 10px 15px;
            }
        }
    }
    &.min {
        .imgWrp {
            margin: 15px;
        }
        .prodTitle {
            font-size: 14px;
        }
        .description {
            font-size: 13px;
        }
        ul {
            li {
                font-size: 13px;
            }
        }
        .tag {
            font-size: 12px;
        }
    }
    &:hover {
        .prodInfo {
            .prodTitle {
                color: $cred2;
            }
        }
    }
}
</style>
