var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"prodItem",class:_vm.side,attrs:{"to":{
        name: 'product',
        params: {
            locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
            category: _vm.d.model.categorySlug,
            slug: _vm.d.model.slug
        }
    }}},[_c('div',{staticClass:"imgWrp"},[(_vm.d.model.image)?_c('img',{attrs:{"src":_vm.path(_vm.d.model.image),"alt":_vm.path(_vm.d.model.image)}}):_vm._e()]),_c('div',{staticClass:"prodInfo"},[_c('div',{staticClass:"prodTitle"},[_vm._v(_vm._s(_vm.d.translate.name))]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.d.translate.release_form))]),_c('ul',_vm._l((_vm.d.translate.catalogIndication),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('router-link',{staticClass:"tag",attrs:{"to":_vm.d.model.categoryUrl}},[_vm._v(_vm._s(_vm.d.model.categoryName))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }