<template>
    <div class="sideSection" :class="mode">
        <template v-if="mode == 'desctop'">
            <div class="caption">{{ title || $t("catalogTitle.news") }}</div>
            <router-link
                v-for="(item, index) in d"
                :key="index"
                :to="{
                    name: 'productscategory',
                    params: {
                        locale: $i18n.locale == 'uk' ? null : $i18n.locale,
                        category: item.slug
                    }
                }"
                :class="{ active: $route.params.category == item.slug }"
                >{{ item.title }}</router-link
            >
        </template>
        <template v-if="mode == 'mob'">
            <div class="mbtitle" @click="slide = !slide">
                {{ title || $t("catalogTitle.news")
                }}<span class="ic-icexpand-down"></span>
            </div>
            <slide-up-down :active="slide">
                <router-link
                    v-for="(item, index) in d"
                    :key="index"
                    :to="{
                        name: 'productscategory',
                        params: {
                            locale: $i18n.locale == 'uk' ? null : $i18n.locale,
                            category: item.slug
                        }
                    }"
                    :class="{ active: $route.params.category == item.slug }"
                    >{{ item.title }}</router-link
                >
            </slide-up-down>
        </template>
    </div>
</template>

<script>
export default {
    name: "CatalogPageFilter",
    props: {
        title: {
            type: String
        }
    },
    data() {
        return {
            mode: "",
            slide: false
        };
    },
    methods: {
        setType() {
            if (window.innerWidth > 1034) {
                this.mode = "desctop";
            } else {
                this.mode = "mob";
            }
        }
    },
    created() {
        this.setType();
        window.addEventListener("resize", this.setType);
    },
    destroyed() {
        window.removeEventListener("resize", this.setType);
    }
};
</script>

<style lang="scss" scoped>
.sideSection.desctop {
    padding-top: 20px;
    .caption {
        @include t4;
        margin-bottom: 15px;
    }
    a {
        display: block;
        padding: 10px;
        @include t4;
        background-color: $cgray6;
        text-decoration: none;
        padding-right: 40px;
        position: relative;
        &:before {
            content: "\e917";
            font-family: "Biopharma" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(-50%, -50%);
            font-size: 20px;
            opacity: 0;
        }
        & + a {
            margin-top: 2px;
        }
        &.active {
            background-color: $cgray7;
            &:before {
                opacity: 1;
            }
        }
    }
}
.sideSection.mob {
    border: 2px solid #d3d6e7;
    border-left: 6px solid #d3d6e7;
    box-sizing: border-box;
    .mbtitle {
        @include t4;
        padding: 16px;
        background-color: $cgray6;
        position: relative;
        .ic-icexpand-down {
            cursor: pointer;
            font-size: 20px;
            top: 50%;
            right: 15px;
            position: absolute;
            transform: translate(0, -50%);
            color: $cgray3;
        }
    }
    a {
        display: block;
        @include t4;
        text-decoration: none;
        color: $cgray3;
        padding: 8px 8px 8px 15px;
        padding-right: 40px;
        position: relative;
        &:before {
            content: "\e917";
            font-family: "Biopharma" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(-50%, -50%);
            font-size: 20px;
            opacity: 0;
        }
        &.active {
            background-color: $cgray7;
            &:before {
                opacity: 1;
            }
        }
    }
}
</style>
