var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sideSection",class:_vm.mode},[(_vm.mode == 'desctop')?[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.title || _vm.$t("catalogTitle.news")))]),_vm._l((_vm.d),function(item,index){return _c('router-link',{key:index,class:{ active: _vm.$route.params.category == item.slug },attrs:{"to":{
                name: 'productscategory',
                params: {
                    locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
                    category: item.slug
                }
            }}},[_vm._v(_vm._s(item.title))])})]:_vm._e(),(_vm.mode == 'mob')?[_c('div',{staticClass:"mbtitle",on:{"click":function($event){_vm.slide = !_vm.slide}}},[_vm._v(" "+_vm._s(_vm.title || _vm.$t("catalogTitle.news"))),_c('span',{staticClass:"ic-icexpand-down"})]),_c('slide-up-down',{attrs:{"active":_vm.slide}},_vm._l((_vm.d),function(item,index){return _c('router-link',{key:index,class:{ active: _vm.$route.params.category == item.slug },attrs:{"to":{
                    name: 'productscategory',
                    params: {
                        locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
                        category: item.slug
                    }
                }}},[_vm._v(_vm._s(item.title))])}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }