<template>
	<div class="pagination">
		<template v-for="(item, index) in compdata">
			<a
				v-if="item.type == 'prev'"
				:key="index"
				href="#"
				class="arrow"
				:class="{ disabled: !item.s }"
				@click.prevent="onPrew(item.s)"
			>
				<span class="ic-icexpand-left"></span>
			</a>
			<a
				v-if="item.type == 'page'"
				:key="index"
				href="#"
				:class="{ current: item.current }"
				@click.prevent="$emit('click', item.l)"
				>{{ item.l }}</a
			>
			<span v-if="item.type == 'dot'" :key="index" class="dots"
				><div></div
			></span>
			<a
				v-if="item.type == 'next'"
				:key="index"
				href="#"
				class="arrow"
				:class="{ disabled: !item.s }"
				@click.prevent="onNext(item.s)"
			>
				<span class="ic-icexpand-right"></span>
			</a>
		</template>
	</div>
</template>

<script>
export default {
	name: "pagination",
	props: ["compdata"],
	methods: {
		onPrew(s) {
			if (s) this.$emit("click", "prev");
		},
		onNext(s) {
			if (s) this.$emit("click", "next");
		},
	},
};
</script>

<style lang="scss">
.pagination {
	display: flex;
	a {
		width: 37px;
		height: 37px;
		text-decoration: none;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 130%;
		text-align: center;
		color: #111325;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		transition: 0.3s ease;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 15px;
			height: 3px;
			transform: translate(-50%, -50%);
			transition: 0.3s ease;
		}
		.ic-icexpand-left,
		.ic-icexpand-right {
			font-size: 20px;
		}
		&:not(.current):hover {
			background-color: $cgray6;
		}
		&.current {
			&::before {
				background-color: $cred1;
			}
		}
	}
	.disabled{
		opacity: 0.3;
		pointer-events: none;
	}
}
</style>