<template>
	<div class="cautionModal">
		<span class="ic-icclose" @click="$emit('close')"></span>
		<div class="imgWrp">
			<div class="desc">
				<img :src="descImg" :alt="descImg" />
			</div>
			<div class="mob">
				<img :src="mobImg" :alt="mobImg" />
			</div>
		</div>
		<ul>
			<li v-html="$store.state.form_products_text1"></li>
		</ul>
		<div v-html="$store.state.form_products_text2"></div>
		<div class="btnsWrp">
			<button @click="$emit('close')">{{ $t("cautionModal.refuse") }}</button>
			<button @click="$emit('accept')">{{ $t("cautionModal.accept") }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "CautionModal",
	computed: {
		descImg() {
			switch (this.locale) {
				case "uk":
					return require(`@/assets/img/modalCaoution.svg`);
				case "ru":
					return require(`@/assets/img/modalCaoutionRu.svg`);
				case "en":
					return require(`@/assets/img/modalCaoutionEn.svg`);
			}
		},
		mobImg() {
			switch (this.locale) {
				case "uk":
					return require(`@/assets/img/Caution1Mob.svg`);
				case "ru":
					return require(`@/assets/img/Caution1MobRu.svg`);
				case "en":
					return require(`@/assets/img/CautionMobEn.svg`);
			}
		},
	},
};
</script>

<style lang="scss">
.cautionModal {
	max-width: 700px;
	width: 100vw;
	background-color: $cwhite;
	@media (max-width: $ts) {
		padding-bottom: 80px;
	}
	.ic-icclose {
		width: 35px;
		height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		color: $cgray4;
		margin-left: auto;
		cursor: pointer;
	}
	.imgWrp {
		padding: 0 50px;
		background-color: $cgray6;
		@media (max-width: $tm) {
			padding: 0 30px;
		}
		img {
			max-width: 100%;
			margin: 0 auto;
			display: block;
		}
		.mob {
			display: none;
			padding: 15px 0;
			@media (max-width: $ts) {
				display: block;
			}
		}
		.desc {
			display: block;
			@media (max-width: $ts) {
				display: none;
			}
		}
	}
	ul {
		padding: 20px 40px 0;
		margin: 0;
		list-style: none;
		@media (max-width: $tm) {
			padding-left: 30px;
			padding-right: 30px;
		}
		li {
			position: relative;
			padding-left: 30px;
			&:before {
				content: "\e909";
				position: absolute;
				top: 0;
				left: 0;
				font-size: 20px;
				color: $cgray3;

				font-family: "Biopharma" !important;
				speak: never;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		li,
		p {
			@include t3l;
			margin: 0;
			& + p {
				margin-top: 16px;
			}
		}
	}
	& > div > p {
		@include t2;
		padding: 15px 40px 0;
		margin: 0;
		@media (max-width: $tm) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.btnsWrp {
		padding: 30px 40px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		button {
			padding: 20px 0;
			background-color: $cgray6;
			border: none;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 120%;
			text-align: center;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #111325;
			cursor: pointer;
		}
	}
}
</style>
